import React from 'react'
import Link from 'gatsby-link'
import SigCap from '../assets/SigCap.png'
import Layout from '../components/Layout'
import Seo from '../components/SEO'

// Create pageData object to simulate markdownRemark object
const pageData = {
  frontmatter: {
    title: "Muhammad Iqbal C. Rochman",
    description: "Experimental Things",
  },
}

const IndexPage = () => (
  <Layout>
    <div>
      <Seo postData={pageData} />

      <h1>Experimental Things</h1>

      <h2 id="sigcap">SigCap</h2>
      <img style={{ float: 'right', margin: '0 1.7rem', width: '250px' }} src={SigCap} alt="SigCap screenshot" />

      <p>
        SigCap is an Android app that simultaneously collects phone's GPS data as well as cellular
        and Wi-Fi information using only the Android API, without requiring root access on the
        device.
      </p>
      <p>
        On each modifiable interval, it collects latitude and longitude data, along with cellular information
        (e.g., 4G channels' PCI, EARFCN, RSRP, RSRQ; 5G channels' RSRP and RSRP) and Wi-Fi information
        (e.g., channel number, frequency, bandwidth, RSSI). It also collects sensor data (under development)
        such as temperature and battery status.
      </p>
      <p>
        SigCap provides device side measurements and can be easily extracted using <a href="#the-map-project">The Map Project</a>.
      </p>
      <p>
        <Link to="https://appdistribution.firebase.dev/i/5b022e1d936d1211">Link to download SigCap</Link>.
        Please use this citation if you use SigCap in your research publication:
        <blockquote>
          V. Sathya, M. I. Rochman, and M. Ghosh, "Measurement-Based Coexistence Studies of LAA & Wi-Fi Deployments in Chicago," in Wireless Communications 28 (1), pp. 136-143, 2020.
        </blockquote>
      </p>

      <h2 id="the-map-project">The Map Project</h2>
      <p>
        The Map Project provides tools to process SigCap and <Link to="https://play.google.com/store/apps/details?id=com.samknows.fcc">FCC Speed Test</Link> data.
        It can creates RSRP/RSSI heatmap using SigCap data, along with markers displaying FCC ST results (DL/UL throughput, latency).
      </p>

      <p>
        Projects built using The Map Project:
        <ul>
          <li><Link to="https://people.cs.uchicago.edu/~muhiqbalcr/grant-park-may-jun-2021/nr-heatmap.html">Measurements of 5G mmWave deployment at Hutchinson Field, Chicago, IL during May - June 2021</Link></li>
          <li><Link to="https://people.cs.uchicago.edu/~muhiqbalcr/chicago/nr-heatmap-chicago.html">Aggregated data from various measurements in Chicago, separated by region (i.e., Downtown, Bronzeville, Hyde Park, UChicago Campus, South)</Link></li>
        </ul>
        Links:
        <ul>
          <li><Link to="https://bitbucket.org/kyuucr/the-map-project">The Map Project code repository</Link></li>
          <li><Link to="https://bitbucket.org/kyuucr/the-map-project/get/master.zip">Download link</Link></li>
          <li><Link to="https://bitbucket.org/kyuucr/the-map-project/get/lite.zip">Download link for the "Lite" version</Link> which only contains scripts to convert JSON to CSV files (recommended for easier usage)</li>
        </ul>
      </p>

    </div>
  </Layout>
)

export default IndexPage
